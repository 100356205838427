//component imports 
import Nav from "./Nav";

//constant imports
import strings from "../../Constants/strings";

//style imports
import "./header.scss";

function Header () {
    return (
        <header className="header">
            <h1 className="header__SiteTitle">{strings.authorFullTitle}</h1>
            <Nav></Nav>
        </header>
    )
}

export default Header;
//components
import EventInfo from "../../Components/EventInfo/EventInfo";

// data
import eventData from "./eventsData";

//styling
import "../../Styling/section.scss";
import "./events.scss";

function Events() {
    const now = new Date();
    const sortedEventData = eventData.sort((a,b)=> a.endDate.getTime() - b.endDate.getTime())
    const pastEvents = sortedEventData.filter(event => event.endDate < now);
    const futureEvents = sortedEventData.filter(event => event.endDate >= now);

    return (
        <section className="events">
            {futureEvents.length > 0 && <>
                <h2 className="events__Title">Upcoming events</h2>
                {futureEvents.map(event => (
                    <EventInfo key={event.name} name={event.name} location={event.location} dateDisplay={event.dateDisplay} />))
                }
            </>}
            {pastEvents.length > 0 && <>
                <h2 className="events__Title">Past events</h2>
                {pastEvents.map(event => (
                    <EventInfo key={event.name} name={event.name} location={event.location} dateDisplay={event.dateDisplay} />
                )).reverse()}
            </>}
        </section>
    )
}

export default Events;
import "./contact.scss";
import "./mailerForm.scss";

export default function Contact() {

    return (
        <section>
            <h2 className="contact__Header">Connect</h2>
            <ul className="contact__socialList">
                <li className="contact__socialListItem">Instagram: <a href="https://www.instagram.com/writertao" target="new">@writertao</a></li>
                <li className="contact__socialListItem">Twitter: <a href="https://x.com/writertao" target="new">@writertao</a></li>
                <li className="contact__socialListItem">Bluesky: <a href="https://bsky.app/profile/dragons.bsky.social" target="new">@dragons</a></li>
            </ul>
            <h2 className="contact__Header">Inquiries</h2>
            <p className="contact__Content">For inquiries, please contact my agent Jennifer Azantian at <a href={"http://www.azantianlitagency.com"} target="new">Azantian Literary Agency</a>.</p>
            <MailerForm />
        </section>
    )
}

function MailerForm() {
    return (
        <div id="mlb2-15531971" className="ml-form-embedContainer ml-subscribe-form ml-subscribe-form-15531971">
            <div className="ml-form-align-center ">
                <div className="ml-form-embedWrapper embedForm">
                    <div className="ml-form-embedBody ml-form-embedBodyDefault row-form">
                        <h3 className="contact__Header">Newsletter</h3>

                        <form className="ml-block-form" action="https://assets.mailerlite.com/jsonp/982307/forms/123812786134320448/subscribe" data-code="" method="post" target="_blank">
                            <div className="ml-form-formContent">

                                <div className="ml-form-fieldRow ml-last-item">
                                    <div className="ml-field-group ml-field-email ml-validate-email ml-validate-required">
                                        <input aria-label="email" aria-required="true" type="email" className="form-control" data-inputmask="" name="fields[email]" placeholder="Email" autoComplete="email"></input>
                                    </div>
                                </div>

                            </div>

                            <input type="hidden" name="ml-submit" value="1"></input>

                            <div className="ml-form-embedSubmit">

                                <button type="submit" className="primary">Subscribe</button>

                                <button disabled={true} style={{ "display": "none" }} type="button" className="loading">
                                    <div className="ml-form-embedSubmitLoad"></div>
                                    <span className="sr-only">Loading...</span>
                                </button>
                            </div>


                            <input type="hidden" name="anticsrf" value="true"></input>
                        </form>
                    </div>
                    <div className="ml-form-successBody row-success" style={{ "display": "none" }}>
                        <div className="ml-form-successContent">
                            <h3 className="contact__Header">Thank you!</h3>
                            <p className="contact__Content">You have successfully subscribed.</p>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}
///styling
import "./eventInfo.scss";

interface EventInfoProps {
    name: string;
    location: string;
    dateDisplay: string;
}

function EventInfo({name, location, dateDisplay}: EventInfoProps) {
 return (
    <div className="eventinfo">
        <h3 className="eventinfo__field">{name}</h3>
        <p className="eventinfo__field">{location}</p>
        <p className="eventinfo__field">{dateDisplay}</p>
    </div>
 )
}

export default EventInfo;
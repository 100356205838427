import "../../Styling/section.scss";

//stlying
import "./about.scss";
import "../../Styling/section.scss";

//asset imports
import profilePic from "../../Assets/Images/goose.jpg";

function About() {
    return (
        <section>
            <h2 className="aboutHeader">About Me</h2>
            <article className="about-card">
                <div className="aboutImgBox">
                    <img className="aboutImgBox__image" src={profilePic} alt="Image of Sophia"/>
                </div>
                <p className="about-card__content">
                Chinese-Canadian author Sophia Tao has dreamed of publishing fantasy stories since she was seven. An engineer with roots in Nanchang and Toronto, she later moved to Seattle to be closer to the mountains and the ocean. <br></br><br></br> Sophia is a finalist for the Mike Resnick Memorial Award for science fiction, a two-time finalist for the PNWA unpublished novel contest, and a graduate of several speculative fiction workshops including Taos Toolbox and Viable Paradise.
                </p>
            </article>
        </section>
    )
}

export default About;

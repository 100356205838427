import {Link} from "react-router-dom";

export default function Error404() {
    return (
        <section>
            <h2>Page not found</h2>
            <p>Sorry, the page you're looking for might not exist.</p>
            <Link to={'/'}>Back home</Link>
        </section>
    )
}